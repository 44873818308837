import { render, staticRenderFns } from "./mangePage1.vue?vue&type=template&id=8cd86a5e&scoped=true&"
import script from "./mangePage1.vue?vue&type=script&lang=js&"
export * from "./mangePage1.vue?vue&type=script&lang=js&"
import style0 from "styles/views/oem/manage.css?vue&type=style&index=0&id=8cd86a5e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8cd86a5e",
  null
  
)

export default component.exports