<template>
  <div>
    <app-main>
      <template v-slot:main-content>
        <div class="content">
          <div class="base-wrapper" style="padding-bottom:50px">
            <div class="base-info">
              基本设置
            </div>
            <div class="mangitem">
              <el-form ref="formData" :model="formData" label-width="100px" class="mangitem">
                <el-form-item label="网站名称">
                    <el-input v-model="formData.web_name" style="width:430px" placeholder="请输入网站名称"></el-input>
                </el-form-item>
                <el-form-item label="网站备案">
                    <el-input v-model="formData.web_beian" style="width:430px" placeholder="请输入网站备案"></el-input>
                </el-form-item>
                <el-form-item label="网站logo">
                    <el-upload
                      class="avatar-uploader"
                      :action="$url+'/api/login/upload'"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess">
                      <img v-if="imageUrl" :src="imageUrl" class="avatar">
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
              </el-form>
            </div>
            <div class="base-info">
              提成设置
              <span class="mange-title-span">* 推广下级开通分站，可得 <span class="mamge-color-blue">￥500</span>元佣金，并且可享受该分站销售订单的永久提成</span>
            </div>
             <div class="mangitem">
                <el-form ref="formData" :model="formData" label-width="100px" class="mangitem">
                <el-form-item label="卖出加价">
                    <el-input v-model="formData.sale_goods_inc" style="width:430px" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="下级分站加价">
                    <el-input v-model="formData.station_goods_inc" style="width:430px" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="用户推广提成" >
                    <el-input v-model="formData.level_price" style="width:430px" placeholder="请输入"></el-input>
                </el-form-item>
              </el-form>
             </div>
             <div class="base-info">
              客服设置
              </div>
               <div class="mangitem">
              <el-form ref="form" :model="formData" label-width="100px" class="mangitem">
                <el-form-item label="电话">
                    <el-input v-model="formData.web_telService" style="width:430px" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="在线客服">
                    <el-input v-model="formData.web_onlineService" style="width:430px" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="QQ客服">
                    <el-input v-model="formData.web_qqService" style="width:430px" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="微信客服">
                    <el-upload
                      class="avatar-uploader"
                      :action="$api_url+'/api/login/upload'"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess1">
                      <img v-if="imageUrl1" :src="imageUrl1" class="avatar">
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
              </el-form>
            </div>
            <div class="mangesavebtn" @click='submit'>保存设置</div>
          </div>
        </div>
      </template>
    </app-main>
  </div>
</template>

<script>
import AppMain from 'components/baseStructure/AppMain'
import { getUserMoney, setStationInfo } from 'network/api'
export default {
  components: {
    AppMain
  },
  data () {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      imageUrl: '', // 网站log
      imageUrl1: '', // 微信客服图片
      formData: {
        web_name: '', // 网站名
        weblogal_image: '', // 网站Logo地址
        web_beian: '', // 网站备案
        web_qqService: '', // qq客服
        web_wechatService_image: '', // 微信客服
        web_onlineService: '', // 在线客服
        web_telService: '', // 电话
        sale_goods_inc: '', // 卖出加价
        station_goods_inc: '', // 下级分站加价
        level_price: '' // 用户推广提成
      }
    }
  },
  computed: {},
  created () {
    // 获取用户信息
    getUserMoney({ appkey: this.$appkey }).then(res => {
      // 获取基本信息的回显数据
      if (res.data.selfconfig) {
        this.formData = res.data.selfconfig
        this.imageUrl =  res.data.selfconfig.weblogal_image
        this.imageUrl1 =  res.data.selfconfig.web_wechatService_image
      }
      // console.log('获取用户信息', res)
    })
  },
  methods: {
    // 保存设置
    submit () {
      this.formData.appkey = this.$appkey
      setStationInfo(this.formData).then(res => {
        if (res.code === 1) {
          this.$message.success('保存成功')
        }
        // console.log('保存设置', res)
      })
    },
    // 网站log图片
    handleAvatarSuccess (res, file) {
      this.formData.weblogal_image = res.code.url
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    // 微信客服图片
    handleAvatarSuccess1 (res, file) {
      this.formData.web_wechatService_image = res.code.url
      this.imageUrl1 = URL.createObjectURL(file.raw)
    }
  }
}
</script>
<style scoped src="styles/views/account/base.css">
</style>
<style>
.phone-code .el-input__inner {
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.btn-code.el-button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.mange-title-span{
  height: 100%;
  margin-left: 32px;
  font-size: 14px;
  color: rgba(0,0,0,.65);
}
.mamge-color-blue{
  color: rgb(24, 144, 255);
}
.http_span{
    float: left;
    position: relative;
    padding: 0 11px;
    color: rgba(0,0,0,.65);
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: 38px;
}
.mangitem {
  margin-top: 30px;
}
.mangesavebtn{
  height: 32px;
  width: 88px;
  line-height: 32px;
  border-radius: 4px;
  text-align: center;
  margin-left: 102px;
  color: #fff;
  font-size: 14px;
  background-color: #1890ff;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
   border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
