<template>
  <div>
    <mangePage1 v-if="tag" />
    <mangePage2 v-if="!tag"/>
  </div>
</template>

<script>
import { getUserMoney } from 'network/api'
import mangePage1 from './mangePage1.vue'
import mangePage2 from './mangePage2.vue'
export default {
  components: {
    mangePage1,
    mangePage2
  },
  data () {
    return {
      editInfo: {},
      tag: false,
      dialogShow: false,
      price: '800',
      money: ''
    }
  },
  created () {
    getUserMoney({ appkey: this.$appkey }).then(res => {
      // 获取基本信息的回显数据
      this.tag = !res.data.selfconfig
      this.editInfo = res.data.selfconfig ? res.data.selfconfig : ''
    })
    // 获取账户余额
    getUserMoney({ appkey: this.$appkey }).then(res => {
      this.money = res.data.usermoney
    })
  },
  methods: {
    rightNowOpening () {
      this.dialogShow = true
    },
    closePayDialog () {
      this.dialogShow = false
    }
  }
}
</script>

<style scoped src="styles/views/oem/manage.css">
</style>
